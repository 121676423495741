import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Lightbox from 'react-images'

class Gallery extends Component {
  constructor() {
    super()

    this.state = {
      lightboxIsOpen: false,
      currentProjectId: 1,
      currentImage: 0,
    }

    this.closeLightbox = this.closeLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
    this.gotoImage = this.gotoImage.bind(this)
    this.handleClickImage = this.handleClickImage.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
  }
  openLightbox(projectId, event) {
    event.preventDefault()
    this.setState({
      currentProjectId: projectId,
      currentImage: 0,
      lightboxIsOpen: true,
    })
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }
  gotoImage(index) {
    this.setState({
      currentImage: index,
    })
  }
  handleClickImage() {
    const currentProject = this.props.projects.find(
      p => p.id === this.state.currentProjectId
    )
    if (
      !currentProject ||
      this.state.currentImage === currentProject.srcSet.length - 1
    ) {
      this.closeLightbox()
      return
    }

    this.gotoNext()
  }
  renderGallery() {
    const { projects } = this.props

    if (!projects) return

    const gallery = projects.map((project, i) => {
      return (
        <article className="6u 12u$(xsmall) work-item" key={i}>
          <a
            className="image fit thumb"
            href={project.src}
            onClick={e => this.openLightbox(project.id, e)}
          >
            <img src={project.src} alt="screenshot" />
          </a>

          <h3>
            { 
              project.href ? 
              <><a
                href={project.href}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  cursor: 'pointer',
                }}
              >
                {project.caption}
              </a>
            <i
            style={{
              paddingLeft: '12px',
              fontSize: '16px',
              textDecoration: 'none',
            }}
            className="fas fa-external-link-alt "
          /></>
            : project.caption
            } 
            
          </h3>

          <p>
            {project.description}{' '}
            {project.source ? (
              <a
                href={project.source}
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                (view on Github)
              </a>
            ) : (
              ''
            )}
          </p>
        </article>
      )
    })

    return <div className="row">{gallery}</div>
  }
  render() {
    const projectScreenshots = this.props.projects
      .find(p => p.id === this.state.currentProjectId)
      .srcSet.map(ss => {
        return { src: ss }
      })

    return (
      <div>
        {this.renderGallery()}
        <Lightbox
          currentImage={this.state.currentImage}
          images={projectScreenshots}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
        />
      </div>
    )
  }
}

Gallery.displayName = 'Gallery'
Gallery.propTypes = {
  projects: PropTypes.array,
}

export default Gallery
