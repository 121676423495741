import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import dp01 from '../assets/images/fulls/dev-portfolio/dev-portfolio.png'
import ll01 from '../assets/images/fulls/lunch-learn/01.png'
import ll02 from '../assets/images/fulls/lunch-learn/02.png'
import ll03 from '../assets/images/fulls/lunch-learn/03.png'
import ll04 from '../assets/images/fulls/lunch-learn/04.png'
import ll05 from '../assets/images/fulls/lunch-learn/05.png'
import sl01 from '../assets/images/fulls/storylines/timeline.png'
import wj02 from '../assets/images/fulls/withjoy/gardening.png'
import wj03 from '../assets/images/fulls/withjoy/hiking.png'
import wj01 from '../assets/images/fulls/withjoy/yoga.png'
import dpThumb from '../assets/images/thumbs/dev-portfolio.png'
import llThumb from '../assets/images/thumbs/lunch-learn.png'
import slThumb from '../assets/images/thumbs/storylines.png'
import wjThumb from '../assets/images/thumbs/withjoy.png'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'

const DEFAULT_IMAGES = [
  {
    id: 1,
    srcSet: [ll01, ll02, ll03, ll04, ll05],
    src: llThumb,
    caption: 'Lunch & Learn',
    href: 'https://lunchandlearn.app',
    description:
      'Team collaboration app written in Vue.js, Typescript using Firebase and Vuetify.',
    source: 'https://github.com/oak-wildwood/lunch-and-learn',
  },
  {
    id: 2,
    srcSet: [wj01, wj02, wj03],
    src: wjThumb,
    caption: 'withjoy.life',
    description:
      'Website for an enterprising young woman. Written in ES6 w/ Vue.js and Vuetify. Backend and hosting using Firebase.',
    source: '',
  },
  {
    id: 3,
    srcSet: [sl01],
    src: slThumb,
    caption: 'Storylines',
    href: 'https://storylines.life',
    description:
      'End of year journaling project using ES6 with Vue.js and Vuetify. Backend and hosting using Firebase.',
    source: 'https://github.com/oak-wildwood/year-in-review',
  },
  {
    id: 4,
    srcSet: [dp01],
    src: dpThumb,
    caption: 'Dev Portfolio',
    href: 'https://oakcooper.com',
    description:
      'This site made for you! Created using Gatsby powered by React and GraphQL. Deployed and hosted with Netlify.',
    source: '',
  },
]

class HomeIndex extends React.Component {
  constructor() {
    super()

    this.state = {
      lightboxIsOpen: false,
      currentProjectId: 1,
      currentImage: 0,
      // contactConfirmationMessage: 'This feature is currently disabled.',
    }

    this.closeLightbox = this.closeLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.handleClickImage = this.handleClickImage.bind(this)
  }

  openLightbox(projectId, event) {
    event.preventDefault()
    this.setState({
      currentProjectId: projectId,
      currentImage: 0,
      lightboxIsOpen: true,
    })
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) return

    this.gotoNext()
  }

  validateForm(form) {
    if (!form) {
      return false
    }
    if (!form.name || !form.name.value) {
      this.setState({
        contactConfirmationMessage: 'Name is required',
      })
      return false
    }

    if (!form.email || !form.email.value) {
      this.setState({
        contactConfirmationMessage: 'Email is required',
      })
      return false
    }

    if (!form.message || !form.message.value) {
      this.setState({
        contactConfirmationMessage: 'Message is required',
      })
      return false
    }

    this.setState({
      contactConfirmationMessage: null,
    })
    return true
  }

  async handleSubmit(event) {
    event.preventDefault()

    const { target: form } = event

    if (!this.validateForm(form)) {
      return
    }

    const data = {
      name: form.name.value,
      email: form.email.value,
      message: form.message.value,
    }

    const response = await (
      await fetch('/.netlify/functions/email', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    ).json()

    this.setState({
      contactConfirmationMessage: response.message,
    })
  }

  render() {
    const siteTitle = 'Oak Cooper'
    const siteDescription =
      'A developer profile and project portfolio for Oak Cooper.'
    const { contactConfirmationMessage } = this.state

    return (
      <Layout>
        <HelmetProvider>
          <Helmet>
            <title>{siteTitle}</title>
            <meta name="description" content={siteDescription} />
          </Helmet>

          <div id="main">
            <section id="one">
              <header className="major">
                <h2>Oak Cooper</h2>
              </header>
              <p>
                Veteran full stack software engineer with a particular focus on
                frontend UI/UX, build automation, Javascript best practices and
                component based development. Oak's sharp attention to detail,
                commitment to continuous delivery and experience with Agile
                methodologies can help your team reach the next level.
              </p>
              <div className="skills-list">
                <div
                  className="fab fa-angular fa-4x"
                  style={{ color: '#b52e31' }}
                />
                <div
                  className="fab fa-html5 fa-4x"
                  style={{ color: '#e34f26' }}
                />
                <div
                  className="fab fa-node-js fa-4x"
                  style={{ color: '#5DBCD2' }}
                />
                <div
                  className="fab fa-vuejs fa-4x"
                  style={{ color: '#42b883' }}
                />
              </div>

              <div className="resume">
                <a
                  href="https://drive.google.com/file/d/1tGYdjNTaOTfHzY5UF5E1xI7Q0RR4AslL/view"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="resume-icon"
                >
                  <i className="fas fa-file-download" />
                  <span className="label">Download Resume</span>
                </a>
              </div>
            </section>

            <section id="two">
              <h2>Recent Work</h2>

              <Gallery
                projects={DEFAULT_IMAGES.map(
                  ({
                    id,
                    src,
                    srcSet,
                    caption,
                    href,
                    description,
                    source,
                  }) => ({
                    id,
                    src,
                    srcSet,
                    caption,
                    href,
                    description,
                    source,
                  })
                )}
              />
            </section>

            {/* <section id="three">
              <h2>Get In Touch</h2>
              <p>
                If you'd like to reach out to me directly please use the contact
                form below and I'll get back to you at my earliest convenience.
              </p>

              <div className="row">
                <div className="8u 12u$(small)">
                  <form
                    noValidate
                    method="post"
                    onSubmit={this.handleSubmit.bind(this)}
                  >
                    <div className="row uniform 50%">
                      <div className="6u 12u$(xsmall)">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          required
                          placeholder="Name"
                        />
                      </div>
                      <div className="6u 12u$(xsmall)">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          required
                          placeholder="Email"
                        />
                      </div>
                      <div className="12u">
                        <textarea
                          name="message"
                          id="message"
                          required
                          placeholder="Message"
                          rows="4"
                        />
                      </div>
                    </div>
                    <ul className="actions">
                      <li>
                        <input type="submit" value="Send Message" />
                      </li>
                      <li className="notification">
                        {contactConfirmationMessage ? (
                          <div className="warning">
                            {contactConfirmationMessage}
                          </div>
                        ) : (
                          ''
                        )}
                      </li>
                    </ul>
                  </form>
                </div>
                <div className="4u 12u$(small)">
                  <ul className="labeled-icons" />
                </div>
              </div>
            </section> */}
          </div>
        </HelmetProvider>
      </Layout>
    )
  }
}

export default HomeIndex
